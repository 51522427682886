













import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';
import StringHelper from '@/helpers/StringHelper';
import ArrayHelper from '@/helpers/ArrayHelper';

export default Vue.extend({
  name: 'AlpineLayoutDefault',
  components: {
    AlpineNavBar: () => import('@/components/navbar/AlpineNavBar.vue'),
  },
  data() {
    return {
      alpineDefaultLayoutMainId: 'alpine-layout-default-main'
    };
  },
  computed: {
    isFullScreen(): boolean {
      return (
        this.$route
        && this.$route.meta
        && this.$route.meta.layout === 'fullscreen'
      );
    },
    getLevel(): Number {
      return this.$route.path.split('/').length - 1 || 1;
    },
    ...mapGetters('session', ['getUserRole', 'userId', 'tokenObject', 'orgId', 'getUserEmail', 'getPermissions']),
    ...mapGetters('oidc', ['oidcIsAuthenticated']),
  },
  watch: {
    $route() {
      // try/catch is just to be overly cautious
      try {
        const ele = document.getElementById(this.alpineDefaultLayoutMainId);
        if (ele) {
          ele.scrollTo(0, 0);
        }
      }
      catch { }
    },
    oidcIsAuthenticated(val)
    {
      if (val)
      {
        this.initializePlugins();
      }
    },
  },
  // UserGuiding added AL-32880
  mounted() {
    this.initializePlugins();
  },
  methods: {
    ...mapActions('session', ['fetchProfile']),
    initializePlugins() {
      this.initializeUserGuiding();
    },
    initializeUserGuiding()
    {
      if (!this.oidcIsAuthenticated)
      {
        return;
      }

      try
      {
        let useUserGuiding = false;
        let href = window.location.href;
        if (!StringHelper.isNullOrEmpty(href))
        {
          href = href.toLowerCase();
          useUserGuiding = href.includes('localhost:5002')
            || href.includes('qa-spirion.com')
            || href.includes('newdev-spirion.com')
            || href.includes('spk8uat1eus2-spirion.com');
        }

        if (!useUserGuiding)
        {
          return;
        }

        const ug = (window as any).userGuiding;

        if (!ug)
        {
          return;
        }
        this.fetchProfile().then(res =>
        {
          const permissions: string[] = this.getPermissions;

          ug.identify(this.userId, {
            role: this.getUserRole,
            email: this.getUserEmail,
            permissions: ArrayHelper.isArraySet(permissions) ? permissions.join(',') : null
          });
        });
      }
      catch {
        // do nothing here.  we just don't want any error with user guiding initialize to effect any page load in our application
      }
    }
  },
});
